export default {
  'kod-ai-in-russia': {
    asideData: {
      title: 'искусственный интеллект',
      date: 'Ноябрь 2024',
      number: 1,
    },
    mainPost: {
      images: {
        desc: '/images/specials/kod-ai-in-russia/tablet.jpg',
        mobile: '/images/specials/kod-ai-in-russia/mobile.jpg',
      },
      title: 'Код искусственного интеллекта в&nbsp;России',
      subtitle: 'Яндекс, билайн, МТС, Сбер, AIRI',
      author: {
        name: 'Пётр Клинский',
        slug: 'piotr',
        picture: {
          url: 'https://kod.ru/content/images/2024/10/DALL-E-2024-10-21-10.44.33---Pixel-art-style-avatar-for-a-30-year-old-article-author--featuring-a-simple-and-minimalistic-design.-The-character-should-have-a-neutral-expression--s.png',
          id: 'piotr',
        },
      },
      readTime: 9,
      views: 324,
    },
    images: {
      desc: '/images/specials/kod-ai-in-russia/desc.jpg',
      tablet: '/images/specials/kod-ai-in-russia/tablet.jpg',
      mobile: '/images/specials/kod-ai-in-russia/mobile.jpg',
    },
    tags: [
      { slug: 'ai', name: 'Ai' },
      { slug: 'in', name: 'Интервью' },
    ],
    main: {
      title:
        'Искусственный интеллект&nbsp;&mdash; это шанс вернуть&nbsp;РФ статус мировой технологической сверхдержавы',
      subtitle:
        'С&nbsp;этим трудно спорить, а&nbsp;самое интересное заключается в&nbsp;том, что это совершенно точно описывает все, что происходит сейчас в&nbsp;России в&nbsp;сфере искусственного интеллекта.',
    },
    author: {
      name: 'Пётр Клинский',
      slug: 'piotr',
      picture: {
        url: 'https://kod.ru/content/images/2024/10/DALL-E-2024-10-21-10.44.33---Pixel-art-style-avatar-for-a-30-year-old-article-author--featuring-a-simple-and-minimalistic-design.-The-character-should-have-a-neutral-expression--s.png',
        id: 'piotr',
      },
    },
  },
}
