import { FC } from 'react'
import Link from 'next/link'

import styles from './Aside.module.scss'
import cn from 'classnames'

import AsideLogo from '../../../public/icons/aside-logo.svg'
import Search from '../../../public/icons/search.svg'
import { getSocName } from '../../../utils/getSocName'
import { ISocial, Socnet } from '../../../interfaces'

interface IPropsAside {
  socials: ISocial[]
  setShowSearchBar: React.Dispatch<React.SetStateAction<boolean>>
  data?: {
    title: string
    number: number
    date: string[]
    specialSlug?: string
  }
}

const Aside: FC<IPropsAside> = ({ socials, setShowSearchBar, data }) => {
  const handlerShowSearchBar = () => {
    setShowSearchBar((prev) => !prev)
  }

  return (
    <>
      <aside className={styles.aside}>
        <button
          type="button"
          className={styles.search}
          onClick={handlerShowSearchBar}
          aria-label="Поиск"
        >
          <Search />
        </button>

        <ul className={styles.soc}>
          {socials.map((s) => (
            <li className={styles.socItem} key={s.code}>
              <Link
                className={styles.socLink}
                href={s.href}
                target="_blank"
                title={getSocName(s.code as Socnet)}
                aria-label={`Ссылка на ${s.label}`}
              >
                {s.icon}
              </Link>
            </li>
          ))}
        </ul>
        {data ? (
          <Link className={styles.content} href={`/${data.specialSlug}`}>
            <p className={styles.data}>
              <span>{data.date[0]}</span> {data.date[1]}
            </p>
            <div className={styles.titleContainer}>
              <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
              <div className={styles.line} />
              <AsideLogo className={styles.asideLogo} />
            </div>
          </Link>
        ) : (
          <div className={cn(styles.content, styles['content--empty'])} />
        )}
      </aside>
      {data && (
        <aside className={cn(styles.aside, styles['aside--mobile'])}>
          <Link className={styles.content} href={`/${data.specialSlug}`}>
            <AsideLogo className={styles.asideLogo} />
            <div className={styles.line} />
            <div>
              <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
              <p className={styles.data}>
                <span>{data.date[0]}</span> {data.date[1]}
              </p>
            </div>
          </Link>
        </aside>
      )}
    </>
  )
}

export default Aside
