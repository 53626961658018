const BLOG_ENDPOINT = 'blogEndpoint'
const GIGA_CHAT_ENDPOINT = 'gigaChatEndpoint'
const BLOG_CLIENT = { clientName: BLOG_ENDPOINT }
const GIGA_CHAT_CLIENT = { clientName: GIGA_CHAT_ENDPOINT }

const TAGS = [
  { code: 'news', name: 'Новости' },
  { code: 'st', name: 'Статьи' },
  { code: 'tg', name: 'Телеграм' },
  { code: 'reviews', name: 'Обзоры' },
  { code: 'in', name: 'Интервью' },
]

const BLOG_TAGS = [
  { code: 'blog', name: 'Блоги' },
  { code: 'jobs', name: 'Вакансии' },
]

const SUMMARY_ARROW = `<svg class="summary-arrow" width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8.24V4.74L5.18 9.4V-9.53674e-07H7.82V9.4L12.5 4.74V8.24L6.5 14.24L0.5 8.24Z" fill="currentColor"/>
</svg>`

const GIGA_CHAT_LINK =
  'https://developers.sber.ru/portal/products/gigachat-api?utm_source=code_durova&utm_medium=ad_article&utm_campaign=ref&utm_content=link'

const CONTENT_WIDTH = 850

const THEME_COLORS = {
  dark: '161c26',
  light: 'fffffe',
}

const SPECIAL_PAGES = ['kod-ai-in-russia']

const ACTIVE_SPECIAL = 'kod-ai-in-russia'

export {
  BLOG_CLIENT,
  TAGS,
  BLOG_TAGS,
  SUMMARY_ARROW,
  GIGA_CHAT_CLIENT,
  BLOG_ENDPOINT,
  GIGA_CHAT_ENDPOINT,
  GIGA_CHAT_LINK,
  CONTENT_WIDTH,
  THEME_COLORS,
  SPECIAL_PAGES,
  ACTIVE_SPECIAL,
}
