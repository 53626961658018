import { INavItem, INavItemMobile } from '../../types'

const MISTAKE_BOT_LINK = 'https://t.me/mistake_kod_bot'

const NAV_ITEMS_MOBILE: INavItemMobile[] = [
  {
    href: '/tag/news',
    name: 'Новости',
    slug: 'news',
  },
  {
    href: '/tag/st',
    name: 'Полезное',
    slug: 'st',
  },
  {
    href: '/tag/ai',
    name: 'Ai',
    slug: 'ai',
  },
  {
    href: '/tag/reviews',
    name: 'Обзоры',
    slug: 'reviews',
  },
  {
    href: '/tag/in',
    name: 'Интервью',
    slug: 'in',
  },
  {
    href: '/tag/blog',
    name: 'Блоги',
    slug: 'blog',
  },
  {
    href: '/work',
    name: 'Карьера',
    slug: 'work',
  },
  {
    href: '/tag/jobs',
    name: 'Вакансии',
    slug: 'jobs',
  },
]

const NAV_ITEMS: INavItem[] = [
  { href: '/tag/news', name: 'Новости' },
  {
    name: 'Статьи',
    subcat: [
      {
        href: '/tag/st',
        name: 'Полезное',
        total: null,
        slug: 'st',
      },
      {
        href: '/tag/reviews',
        name: 'Обзоры',
        total: null,
        slug: 'reviews',
      },
      {
        href: '/tag/in',
        name: 'Интервью',
        total: null,
        slug: 'in',
      },
      {
        href: '/tag/tg',
        name: 'Telegram',
        total: null,
        slug: 'tg',
      },
    ],
  },
  {
    href: '/tag/ai',
    name: 'Ai',
    total: null,
    slug: 'ai',
  },
  { href: '/tag/reviews', name: 'Обзоры', total: null, slug: 'reviews' },
  { href: '/tag/blog', name: 'Блоги', total: null, slug: 'blog' },
  {
    href: '/work',
    name: 'Карьера',
    slug: 'work',
    subcat: [
      {
        href: '/work',
        name: 'Статьи',
      },
      {
        href: '/reviews',
        name: 'Отзывы',
      },
      {
        href: '/tag/jobs',
        name: 'Вакансии',
      },
    ],
  },
]

export { MISTAKE_BOT_LINK, NAV_ITEMS_MOBILE, NAV_ITEMS }

export type { INavItemMobile, INavItem }
